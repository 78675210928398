var render = function () {
  var _vm$materi, _vm$materi2, _vm$materi3, _vm$materi4, _vm$materi$file_mater, _vm$materi$file_mater2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "11",
      "md": "8",
      "lg": "7"
    }
  }, [_c('b-card', [_c('h3', {
    staticClass: "mb-1"
  }, [_c('strong', [_vm._v(_vm._s((_vm$materi = _vm.materi) === null || _vm$materi === void 0 ? void 0 : _vm$materi.nama_materi))])]), _c('p', {
    staticClass: "text-secondary mb-2"
  }, [_c('i', {
    staticClass: "d-block"
  }, [_c('strong', [_vm._v("Deskripsi Materi:")])]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s((_vm$materi2 = _vm.materi) === null || _vm$materi2 === void 0 ? void 0 : _vm$materi2.deskripsi))])]), (_vm$materi3 = _vm.materi) !== null && _vm$materi3 !== void 0 && _vm$materi3.file_materi && ((_vm$materi4 = _vm.materi) === null || _vm$materi4 === void 0 ? void 0 : _vm$materi4.file_materi.length) > 0 ? _c('div', [_c('strong', [_vm._v(" Lampiran terupload: "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$materi$file_mater = _vm.materi.file_materi[0]) === null || _vm$materi$file_mater === void 0 ? void 0 : _vm$materi$file_mater.file
    }
  }, [_vm._v(_vm._s(_vm.getFileName((_vm$materi$file_mater2 = _vm.materi.file_materi[0]) === null || _vm$materi$file_mater2 === void 0 ? void 0 : _vm$materi$file_mater2.file)))])])]) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }