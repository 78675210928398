<template>
  <b-overlay :show="loading">
    <b-row class="justify-content-center">
      <b-col sm="11" md="8" lg="7">
        <b-card>
          <h3 class="mb-1">
            <strong>{{ materi?.nama_materi }}</strong>
          </h3>
          <p class="text-secondary mb-2">
            <i class="d-block"><strong>Deskripsi Materi:</strong></i>
            <span class="d-block">{{ materi?.deskripsi }}</span>
          </p>
          <div v-if="materi?.file_materi && materi?.file_materi.length > 0">
            <strong>
              Lampiran terupload:
              <a target="_blank" :href="materi.file_materi[0]?.file">{{
                getFileName(materi.file_materi[0]?.file)
              }}</a>
            </strong>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard,
    BTable,
  },
  data: () => ({
    form: {
      nama_materi: null,
      deskripsi: null,
      file: null,
      uploaded_file: null,
    },
    id: null,
    materi: null,
    loading: false,
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    search: null,
    fields: [
      { key: "index", label: "No" },
      { key: "nama_materi", label: "Judul Materi", sortable: true },
      { key: "deskripsi", label: "Deskripsi", sortable: true },
      { key: "action", label: "#" },
    ],
    selected: [],
    total: [],
    record: [],
    optFilter: [
      { id: "name", value: "Nama" },
      // { id: "nip", value: "NIP" },
    ],
  }),
  computed: {
    isValidForm() {
      if (!this.form.nama_materi || !this.form.deskripsi) {
        return false;
      }

      return true;
    },
  },
  methods: {
    viewDetail(item) {
      this.$router.push("/materi/" + item.id);
    },
    getFileName(path) {
      const array = path.split("/");
      return array[array.length - 1];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMateri() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "materi/getDetail",
          this.$route.params.id
        );
        this.materi = response;
        console.log("ma", this.materi);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.go(-1);
    }
    this.getMateri();
  },
};
</script>
